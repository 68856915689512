@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Tahoma";
}

/* scroll bar */

/* scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}



p {
  color: #2d3e50;
}
.checkbox:checked + .check-icon {
  display: flex;
}

/* for incident table */

.App {
  font-family: sans-serif;
  /* text-align: center; */
}

.rdt_Table {
  margin-bottom: 1rem;
}
.rdt_TableRow {
  background-color: grey;
}
.rdt_TableCol {
  color: black;
  font-weight: bolder;
}
.rdt_TableCol_Sortable {
  /* padding-left: 1.5rem; */
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
  font-size: 1rem;
  text-align: left;
  padding-right: 0.75rem;
}
.rdt_TableCell {
  --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
    font-size: 1rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    white-space: nowrap;
}
.rdt_TableHeader {
  display : none !important;
}
.rdt_TableFooter {
}
.rdt_TableHead {
}
.rdt_TableHeadRow {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity)) !important;
}
.rdt_TableBody {
  --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.zruCi{
  min-height: 60px !important;
}
.rdt_ExpanderRow {
}


.loader {
	border-top-color: #3498db;
	-webkit-animation: spinner 1.5s linear infinite;
	animation: spinner 1.5s linear infinite;
}

.swal2-title {
  font-size: 1.4rem !important;
}

.swal2-html-container{
  font-size: 1.1em !important;
  line-height: 1.5em !important;
  font-weight: 500 !important;
}
.swal2-popup{
  padding: 2em 1.5em !important;
  width: 36em !important;
}
.swal2-styled.swal2-confirm{
  background-color: white !important;
  color: black !important;
  border: 2px solid black !important;
}
.swal2-styled.swal2-cancel{
  border: 2px solid black !important;
}

.rmsc .clear-selected-button {
  display: none !important;
}
.tag-item ~ .tag-item::before {
  content: ', '; 
}
.tag-remove{
  display: none !important;
}
.tag{
      background-color: transparent !important;
     border: none !important;
}

@-webkit-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}


input:checked ~ .radio {
  color:white;
  background-color: green;
}

.mainTitle {
  font-family: "Arial black";
}

.hidden{
  display: none;
}

.card{
  border-radius: 5%;
  border: 2px solid #2d3e50;
  
}

.button {
  border-radius: 4px;
  background-color: green;
  border: none;
  color: #ffffff;
  text-align: center;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 25px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}

.previousBtn {
  border-radius: 4px;
  background-color: #000000;
  border: none;
  color: #ffffff;
  text-align: center;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.previousBtn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.previousBtn span:after {
  content: "\00ab";
  position: absolute;
  opacity: 0;
  top: 0;
  left: -20px;
  transition: 0.5s;
}

.previousBtn:hover span {
  padding-left: 25px;
}

.previousBtn:hover span:after {
  opacity: 1;
  left: 0;
}

.progress-line,
.progress-line:before {
  height: 3px;
  width: 100%;
  margin: 0;
}

.progress-line {
  background-color: lightgray;
  display: -webkit-flex;
  display: flex;
}

.progress-line:before {
  background-color: black;
  content: "";
  -webkit-animation: running-progress 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  animation: running-progress 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@-webkit-keyframes running-progress {
  0% {
      margin-left: 0px;
      margin-right: 100%;
  }

  50% {
      margin-left: 25%;
      margin-right: 0%;
  }

  100% {
      margin-left: 100%;
      margin-right: 0;
  }
}

@keyframes running-progress {
  0% {
      margin-left: 0px;
      margin-right: 100%;
  }

  50% {
      margin-left: 25%;
      margin-right: 0%;
  }

  100% {
      margin-left: 100%;
      margin-right: 0;
  }
}

.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  border-radius: 2px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #04aa6d;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #04aa6d;
  cursor: pointer;
}

.radio-toolbar {
  margin: 10px;
}

.radio-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-toolbar label {
  display: inline-block;
  background-color: #efefef;
  padding: 3px 15px;
  margin: 2px;
  font-size: 1rem;
  border: 2px solid #2d3e50;
  border-radius: 15px;
}

.radio-toolbar label:hover {
  background-color: #6b7a8f;
}

.radio-toolbar input[type="radio"]:checked+label {
  background-color: #6b7a8f;
  border-color: #2d3e50;
}

nav {
  width: auto;
}

